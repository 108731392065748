import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts"
import { columnCards, sliderCompanies, subpageContent } from "../utils/products/subpageInformation";
import { bannerCMS } from "../utils/generalInformation";
import ImageCarousel from "../components/image-carousel/image-carousel";
import Comparison from "../components/comparison/comparison";
import InfoImage from "../components/info-image/info-image";
import Banner from "../components/banner/banner";




// markup
const Breakdown = () => {


    return (
        <Layout
            SEOTitle={"Motor Breakdown Recovery - United Legal Assistance"}
            SEODescription={"We understand how important it is to have the right motor breakdown cover for your needs. Which is why our products are designed to keep you on the move."}
            footerDisclaimer={""}>

            <InfoImage props={subpageContent} origin="economy" />
            <InfoImage props={subpageContent} origin="fullCover" />

            <Banner props={bannerCMS} />

            <Comparison props={columnCards} />

            <ImageCarousel props={sliderCompanies} />

        </Layout>
    )

}

export default Breakdown